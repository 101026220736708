import React, {Suspense}       from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import {useTranslation}        from "react-i18next";

const OrderPageLayout = React.lazy(() => import("../../entities/order/OrderPageLayout"));
const OrderFirstStepPage = React.lazy(() => import("../../pages/order/firstStep/OrderFirstStepPage"));
const OrderSecondStepPage = React.lazy(() => import("../../pages/order/secondStep/OrderSecondStepPage"));
const OrderThirdStepPage = React.lazy(() => import("../../pages/order/thirdStep/OrderThirdStepPage"));
const Registration = React.lazy(() => import("../../pages/registration/Registration"));
const FF = React.lazy(() => import("../../pages/registration/FF/FF"));
const Egov = React.lazy(() => import("../../pages/registration/Egov/Egov"));
const TransferProfile = React.lazy(() => import("../../pages/registration/TransferProfile/TransferProfile"));
const NotCitizen = React.lazy(() => import("../../pages/registration/NotCitizen/NotCitizen"));
const WithDocument = React.lazy(() => import("../../pages/registration/WithDocument/WithDocument"));
const ManualRegistration = React.lazy(() => import("../../pages/registration/ManualRegistration/ManualRegistration"));
const TransferComplete = React.lazy(() => import("../../pages/registration/TransferComplete/TransferComplete"));
const SpecialistReviews = React.lazy(() => import("../../pages/reviews/Specialist/SpecialistReviews"));
const ClientReviews = React.lazy(() => import("../../pages/reviews/Client/ClientReviews"));
const SelfReviews = React.lazy(() => import("../../pages/reviews/Self/SelfReviews"));
const CashLoans = React.lazy(() => import("../../pages/specialist/loans/CashLoans"));
const Referral = React.lazy(() => import("../../pages/customers/referral/Referral"));
const ClientProfile = React.lazy(() => import("../../pages/client/profile/ClientProfile"));
const SpecialistAppPrices = React.lazy(() => import("../../pages/client/specialist-prices/SpecialistAppPrices"));
const AutoRenewalCancel = React.lazy(() => import("../../pages/state/autoRenewalCancel/AutoRenewalCancel"));
const Credits = React.lazy(() => import("../../pages/specialist/сredits/Credits"));
const CreditsSchedule = React.lazy(() => import("../../pages/specialist/credits-schedule/CreditsSchedule"));
const PurchaseListPage = React.lazy(() => import("../../pages/specialist/tariffs/PurchaseListPage/PurchaseListPage"));
const PeriodsListPage = React.lazy(() => import("../../pages/specialist/tariffs/PeriodsListPage/PeriodsListPage"));
const TariffOptionsPage = React.lazy(() => import("../../pages/specialist/tariffs/TariffOptionsPage/TariffOptionsPage"));
const PurchaseCatalogueList = React.lazy(() => import("../../pages/specialist/tariffs-catalogue/catalogueList/PurchaseCatalogueList"));
const PurchaseServicesList = React.lazy(() => import("../../pages/specialist/tariffs-catalogue/servicesList/PurchaseServicesList"));
const SpecialistProfile = React.lazy(() => import("../../pages/specialist/profile/SpecialistProfile"));
const ProfileAbout = React.lazy(() => import("../../pages/specialist/profile-about/ProfileAbout"));
const ProfilePrices = React.lazy(() => import("../../pages/specialist/profile-prices/ProfilePrices"));
const PricesService = React.lazy(() => import("../../pages/specialist/profile-prices-service/PricesService"));
const PricesWork = React.lazy(() => import("../../pages/specialist/profile-prices-work/PricesWork"));
const RemoveProfile = React.lazy(() => import("../../pages/specialist/profile-remove/RemoveProfile"));
const ProfileMedia = React.lazy(() => import("../../pages/specialist/profile-media/ProfileMedia"));
const ProfileMediaUpload = React.lazy(() => import("../../pages/specialist/profile-media-upload/ProfileMediaUpload"));
const ProfileAvatarChange = React.lazy(() => import("../../pages/specialist/profile-avatar-change/ProfileAvatarChange"));


export interface RouteItem {
  path: string,
  element: JSX.Element,
  title?: string,
  children?: {
    path: string,
    element: JSX.Element,
  }[];
}

const routes: RouteItem[] = [
  {
    path:     "order/*",
    element:  <OrderPageLayout />,
    children: [
      {
        path:    "",
        element: <OrderFirstStepPage />,
      },
      {
        path:    "second-step",
        element: <OrderSecondStepPage />,
      },
      {
        path:    "third-step",
        element: <OrderThirdStepPage />,
      },
    ],
  },
  {
    path:    "registration",
    element: <Registration />,
  },
  {
    path:    "freedom",
    element: <FF />,
  },
  {
    path:    "egov",
    element: <Egov />,
  },
  {
    path:    "transfer",
    element: <TransferProfile />,
  },
  {
    path:    "non-resident",
    element: <NotCitizen />,
  },
  {
    path:    "document",
    element: <WithDocument />,
  },
  {
    path:    "transfer-complete",
    element: <TransferComplete />,
  },
  {
    path:    "specialist-reviews/:id/:service_id?",
    element: <SpecialistReviews />,
  },
  {
    path:    "client-reviews/:id",
    element: <ClientReviews />,
  },
  {
    path:    "specialist-self-reviews/:service_id?",
    element: <SelfReviews />,
  },
  {
    path:     "tariffs/*",
    element:  <Outlet />,
    children: [
      {
        path:    "",
        element: <PurchaseListPage />,
      },
      {
        path:    "periods",
        element: <PeriodsListPage />,
      },
      {
        path:    "options/:period/:is_subscribe?",
        element: <TariffOptionsPage />,
      },
      {
        path:    "catalogue",
        element: <PurchaseCatalogueList />,
      },
      {
        path:    "catalogue/services",
        element: <PurchaseServicesList />,
      },
    ],
  },
  {
    path:     "state/*",
    element:  <Outlet />,
    children: [
      {
        path:    "auto-renewal-cancel",
        element: <AutoRenewalCancel />,
      },
    ],
  },
  {
    path:     "credits/*",
    element:  <Outlet />,
    children: [
      {
        path:    "",
        element: <Credits />,
      },
      {
        path:    "schedule/:id",
        element: <CreditsSchedule />,
      },
    ],
  },
  {
    path:    "specialist-cash-loans",
    element: <CashLoans />,
  },
  {
    path:    "referral",
    element: <Referral />,
  },
  {
    path:     "profile/*",
    element:  <Outlet />,
    children: [
      {
        path:    "*",
        element: <SpecialistProfile />,
      },
      {
        path:    ":id/*",
        element: <SpecialistProfile />,
      },
      {
        path:    "about",
        element: <ProfileAbout />,
      },
      {
        path:    "prices",
        element: <ProfilePrices />,
      },
      {
        path:    "prices-service/:id",
        element: <PricesService />,
      },
      {
        path:    "prices-work/:id",
        element: <PricesWork />,
      },
      {
        path:    "remove",
        element: <RemoveProfile />,
      },
      {
        path:    "media/:id?",
        element: <ProfileMedia />,
      },
      {
        path:    "media/add",
        element: <ProfileMediaUpload />,
      },
      {
        path:    "avatar-change",
        element: <ProfileAvatarChange />,
      },
    ],
  },
  {
    path:    "client-profile/:id",
    element: <ClientProfile />,
  },
  {
    path:    "specialist-prices/:id/:service_id?",
    element: <SpecialistAppPrices />,
  },
]

const RootRoutes = () => {
  const {t} = useTranslation();

  return <Routes>
    {routes.map((route) => (
      <Route key={route.path} path={route.path} element={<Suspense>{route.element}</Suspense>}>
        {!!route.children?.length && route.children.map((child) => (
          <Route key={child.path} path={child.path} element={<Suspense>{child.element}</Suspense>} />
        ))}
      </Route>
    ))}
    <Route path="/manual" element={
      <ManualRegistration
        navSubtitle={t("page.registration.manual_registration.header.description")}
        type="manual"
      />}
    />
  </Routes>
}
export default RootRoutes;