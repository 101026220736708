import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LabelItem}                  from "shared/ui";
import {Nullable}                   from "shared/model";

export interface WorkUnitItemType {
  id: Nullable<number>;
  name: string;
  is_selected: boolean;
}

export interface AddressesItem {
  coord: number[];
  name: string;
  city_id?: number;
  city_name?: string;
}

export interface AddressItemType {
  address: string;
  longitude: number;
  latitude: number;
  entrance: Nullable<number>;
  apartment: Nullable<number>;
  city: {
    id: number;
    name: string;
  }
}

export interface ImageFromNativeItem {
  uuid?: string;
  type: string;
  base64?: string;
  percent?: number;
  isFailed?: boolean;
  digest?: string;
}

export interface OrderFirstStepData {
  description: string;
  isFast: boolean;
  date?: string;
  dateTab?: string;
  dateAndTime?: {
    date?: string;
    time?: string;
  },
  time?: string;
  media: ImageFromNativeItem[];
  uploadedMedia: ImageFromNativeItem[];
  labels?: LabelItem[];
}

export interface OrderSecondStepData {
  priceUnit?: string;
  recommendedPrice: Nullable<{
    price?: string;
    work?: string;
  }>;
  price?: number;
  isAppPaymentWay?: boolean;
  workUnits: WorkUnitItemType[];
  price_limit?: {
    min: number;
    max: number;
  };
  labels?: LabelItem[];
}

export interface OrderThirdStepData {
  addresses: AddressesItem[];
  isAllowed: boolean;
  phone?: string;
  labels?: LabelItem[];
}

interface OrderFormState {
  currentCity?: { id?: number, name?: string },
  stepCount: number,
  firstStep: OrderFirstStepData,
  secondStep: OrderSecondStepData,
  thirdStep: OrderThirdStepData,
  analytics?: { [key: string]: unknown },
  descriptionError: string;
  priceError: string;
  phoneError: string;
  ticketId?: string,
  successResponse: boolean,
}

const initialState: OrderFormState = {
  currentCity:      undefined,
  stepCount:        0,
  firstStep:        {
    description:   "",
    isFast:        false,
    date:          undefined,
    dateTab:       undefined,
    dateAndTime:   undefined,
    media:         [],
    uploadedMedia: [],
    labels:        [],
  },
  secondStep:       {
    priceUnit:        undefined,
    recommendedPrice: null,
    price:            undefined,
    isAppPaymentWay:  false,
    workUnits:        [],
    price_limit:      undefined,
    labels:           [],
  },
  thirdStep:        {
    addresses: [],
    isAllowed: false,
    phone:     undefined,
    labels:    [],
  },
  analytics:        undefined,
  descriptionError: "",
  priceError:       "",
  phoneError:       "",
  ticketId:         undefined,
  successResponse:  false,
}

const slice = createSlice({
  name:     "order",
  initialState,
  reducers: {
    updateFirstStep(state, action: PayloadAction<Partial<OrderFormState["firstStep"]>>) {
      state.firstStep = {...state.firstStep, ...action.payload}
    },
    updateSecondStep(state, action: PayloadAction<Partial<OrderFormState["secondStep"]>>) {
      state.secondStep = {...state.secondStep, ...action.payload}
    },
    updateThirdStep(state, action: PayloadAction<Partial<OrderFormState["thirdStep"]>>) {
      state.thirdStep = {...state.thirdStep, ...action.payload}
    },
    updateStepCount(state, action: PayloadAction<Partial<OrderFormState["stepCount"]>>) {
      state.stepCount = action.payload
    },
    updateAnalytics(state, action: PayloadAction<Partial<OrderFormState["analytics"]>>) {
      state.analytics = action.payload
    },
    setCurrentCity(state, action: PayloadAction<Partial<OrderFormState["currentCity"]>>) {
      state.currentCity = action.payload
    },
    setDescriptionError(state, action: PayloadAction<string>) {
      state.descriptionError = action.payload
    },
    setPriceError(state, action: PayloadAction<string>) {
      state.priceError = action.payload
    },
    setPhoneError(state, action: PayloadAction<string>) {
      state.phoneError = action.payload
    },
    setSuccessResponse(state, action: PayloadAction<boolean>) {
      state.successResponse = action.payload
    },
  },
})

export const {
  updateStepCount,
  updateFirstStep,
  updateSecondStep,
  updateThirdStep,
  updateAnalytics,
  setCurrentCity,
  setDescriptionError,
  setPriceError,
  setPhoneError,
  setSuccessResponse,
} = slice.actions;
export default slice.reducer;

export const selectCurrentCity = (state: { order: typeof initialState }) => state.order.currentCity;
export const selectStepCount = (state: { order: typeof initialState }) => state.order.stepCount;
export const selectFirstStepData = (state: { order: typeof initialState }) => state.order.firstStep;
export const selectSecondStepData = (state: { order: typeof initialState }) => state.order.secondStep;
export const selectThirdStepData = (state: { order: typeof initialState }) => state.order.thirdStep;
export const selectDescriptionError = (state: { order: typeof initialState }) => state.order.descriptionError;
export const selectPriceError = (state: { order: typeof initialState }) => state.order.priceError;
export const selectPhoneError = (state: { order: typeof initialState }) => state.order.phoneError;
export const selectSuccessResponse = (state: { order: typeof initialState }) => state.order.successResponse;
export const selectOrderAnalytics = (state: { order: typeof initialState }) => state.order.analytics;