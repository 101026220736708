import {useEffect}     from "react";
import {NativeActions} from "shared/model";

function parseOrReturn(data: any) {
  if (typeof data === "string") {
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  }
  return data;
}

export function useNativeHandler<T>(
  module: string | null,
  action: NativeActions,
  callback: (data: T) => void,
) {
  useEffect(() => {
    function handleNativeMessage(event: any) {
      const {module: eventModule, action: eventAction, data} = event.detail;
      const parsedData = parseOrReturn(data);

      if (eventAction === action && (!module || module === eventModule)) {
        callback(parsedData);
      }
    }

    window.addEventListener("nativeMessage", handleNativeMessage);

    return () => {
      window.removeEventListener("nativeMessage", handleNativeMessage);
    };
  }, [module, action, callback]);
}

type ResolveMethod<T> = (...values: T[]) => void;
type RejectMethod<Error> = (reason: Error) => void;
type CallbackMethod<T> = (resolve: ResolveMethod<T>, reject: RejectMethod<Error>) => void;

export const connect = <T>(callback: CallbackMethod<T>) => {
  return new Promise<T>((resolve, reject) => {
    try {
      callback(resolve, reject);
    } catch (e: unknown) {

      reject(e);
    }
  });
};

export const tryNativeMethod = (callback: () => void, errorMessage?: string) => {
  try {
    callback();
  } catch (e: unknown) {
    if (e instanceof TypeError) {
      throw errorMessage ? new TypeError(errorMessage) : e;
    }

    throw e;
  }
};

export function nativeMethod() {
  return {
    checkCameraPermission() {
      return connect<{ has_permissions: boolean }>((resolve, reject) => {
        console.log(`send ${NativeActions.HAS_PERMISSIONS_CAMERA} action`)
        tryNativeMethod(
          () => window.NativeApp[NativeActions.HAS_PERMISSIONS_CAMERA](),
          `Native app does not implement \`window.NativeApp.${NativeActions.HAS_PERMISSIONS_CAMERA}\``,
        );

        const listener = listenToNativeMessage<{
          has_permissions: boolean
        }>(null, NativeActions.HAS_PERMISSIONS_CAMERA, (data) => {
          resolve(data);
          listener();
        }, (error) => {
          reject(error);
          listener();
        });
      });
    },
    checkGalleryPermission() {
      return connect<{ has_permissions: boolean }>((resolve, reject) => {
        tryNativeMethod(
          () => window.NativeApp[NativeActions.HAS_PERMISSIONS_GALLERY](),
          `Native app does not implement \`window.NativeApp.${NativeActions.HAS_PERMISSIONS_GALLERY}\``,
        );

        const listener = listenToNativeMessage<{
          has_permissions: boolean
        }>(null, NativeActions.HAS_PERMISSIONS_GALLERY, (data) => {
          resolve(data);
          listener();
        }, (error) => {
          reject(error);
          listener();
        });
      });
    },
    checkGeoPermission() {
      return connect<{ has_permissions: boolean }>((resolve, reject) => {
        tryNativeMethod(
          () => window.NativeApp[NativeActions.HAS_PERMISSIONS_GEO](),
          `Native app does not implement \`window.NativeApp.${NativeActions.HAS_PERMISSIONS_GEO}\``,
        );

        const listener = listenToNativeMessage<{
          has_permissions: boolean
        }>(null, NativeActions.HAS_PERMISSIONS_GEO, (data) => {
          resolve(data);
          listener();
        }, (error) => {
          reject(error);
          listener();
        });
      });
    },
    checkPushPermission() {
      return connect<{ has_permissions: boolean }>((resolve, reject) => {
        tryNativeMethod(
          () => window.NativeApp[NativeActions.HAS_PERMISSIONS_PUSH](),
          `Native app does not implement \`window.NativeApp.${NativeActions.HAS_PERMISSIONS_PUSH}\``,
        );

        const listener = listenToNativeMessage<{
          has_permissions: boolean
        }>(null, NativeActions.HAS_PERMISSIONS_PUSH, (data) => {
          resolve(data);
          listener();
        }, (error) => {
          reject(error);
          listener();
        });
      });
    },
  };
}

export function listenToNativeMessage<T>(
  module: string | null,
  action: NativeActions,
  onMessage: (data: T) => void,
  onError: (error: Error) => void,
) {
  function handleNativeMessage(event: any) {
    try {
      const {module: eventModule, action: eventAction, data} = event.detail;
      if (eventAction === action && (!module || module === eventModule)) {
        const parsedData = parseOrReturn(data);
        onMessage(parsedData);
      }
    } catch (error) {
      console.warn("something went wrong");
      onError(error as Error);
    }
  }

  window.addEventListener("nativeMessage", handleNativeMessage);
  console.log(`подписка на ${action}`);

  return () => {
    window.removeEventListener("nativeMessage", handleNativeMessage);
    console.log(`отписка от ${action}`);
  };
}
