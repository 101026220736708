import React                       from "react";
import {useSetHeaders}             from "shared/lib";
import {useAppDispatch}            from "shared/model";
import {getCurrentDate}            from "features/order/pickDate/model/getCurrentTime";
import {useFetchOrderDetailsQuery} from "../../api/orderDetailsApi";
import {
  OrderFirstStepData,
  OrderSecondStepData,
  OrderThirdStepData,
  setCurrentCity,
  updateAnalytics,
  updateFirstStep,
  updateSecondStep,
  updateThirdStep,
}                                  from "../slice/orderSlice";


export function useSetDataFromRequest() {
  const dispatch = useAppDispatch();
  const {isSuccess, ticketId, workId, isDemo} = useSetHeaders();
  const {data: orderDetails} = useFetchOrderDetailsQuery({
    ticket_id: ticketId ?? undefined,
    work_id:   workId ?? undefined,
  }, {skip: !isSuccess});

  React.useEffect(() => {
    if (orderDetails) {
      const {content, analytics} = orderDetails;
      const {ticket, labels, price_limit, city} = content;

      const media = ticket.media?.map((item) => ({
        uuid:     undefined,
        type:     item.type === "photo" ? "img" : "video",
        base64:   undefined,
        percent:  100,
        isFailed: false,
        digest:   item.digest,
      })) ?? [];

      const firstStateData: OrderFirstStepData = {
        description:   ticket.description,
        media,
        uploadedMedia: [],
        isFast:        ticket.is_fast ?? false,
        dateTab:       setDateTab(ticket.is_fast ?? false, ticket.date, ticket.time),
        dateAndTime:   setDateAndTime(ticket.date, ticket.time),
        date:          !ticket.time && ticket.date ? ticket.date : undefined,
        labels:        labels.media ?? [],
        time:          ticket.time,
      }

      const secondStateData: OrderSecondStepData = {
        priceUnit:        ticket.work_units?.find((item) => item.is_selected)?.name,
        recommendedPrice: ticket.recommended_price,
        price:            typeof ticket.price_to === "number" ? ticket.price_to : undefined,

        isAppPaymentWay: ticket.is_app_payment_way,
        workUnits:       ticket.work_units ?? [],
        price_limit:     price_limit ? {
          min: price_limit.min,
          max: price_limit.max,
        } : undefined,
        labels:          labels.app_payment ?? [],
      }

      const thirdStateData: OrderThirdStepData = {
        addresses: ticket.addresses?.map((address) => ({
          coord:     [+address.longitude, +address.latitude],
          name:      address.address,
          city_id:   address.city.id,
          city_name: address.city.name,
        })) ?? [],
        isAllowed: ticket.is_allowed,
        phone:     isDemo ? undefined : ticket.phone.slice(2),
        labels:    labels.addresses ?? [],
      }
      dispatch(updateFirstStep(firstStateData));
      dispatch(updateSecondStep(secondStateData));
      dispatch(updateThirdStep(thirdStateData));
      dispatch(setCurrentCity(city));
      dispatch(updateAnalytics(analytics));
    }
  }, [orderDetails]);

  return {orderDetails, city: orderDetails?.content.city, ticketId, workId, isDemo};
}

function setDateAndTime(date?: string, time?: string) {
  if (date && time) {
    const currentTime = time.replace("-", " - ");
    return {date: date, time: currentTime};
  }
  return undefined;
}

function setDateTab(isFast: boolean, date?: string, time?: string) {
  if (isFast) return "fast";

  if (date && time) {
    if (new Date(date) >= new Date(getCurrentDate())) {
      return "selectDate"
    }
  }

  if (date && new Date(date) >= new Date(getCurrentDate())) {
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();

    return `${year}-${month}-${day}`;
  }

  return undefined;
}