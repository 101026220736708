export const permissions = {
  camera:      {
    text:    "Камераға қолжетімділік",
    subtext: "Фото түсіру немесе видео жазу үшін камераға рұқсат беру қажет",
  },
  gallery:     {
    text:    "Галереяға қолжетімділік",
    subtext: "Галереядан фото мен видео жүктеу үшін рұқсат беру қажет",
  },
  geolocation: {
    text:          "Орналасқан жерге қолжетімділік",
    subtext:       "Сіздің мекенжайыңызды анықтау үшін геолокацияға рұқсат беру қажет",
    cancel_button: "Кейін еске салу",
  },
}