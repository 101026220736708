import {Nullable}        from "shared/model";
import {ReviewsListItem} from "../../../../services/reviews/types";

export enum SpecialistProfileState {
  LOCKED = "locked",
  GUEST = "guest",
  REJECT = "reject",
  REMOVED = "removed",
  APPLIED = "applied",
}

export interface SpecialistAlertItem {
  type: string;
  is_closable?: boolean;
  text: string;
  style?: string;
  title: string;
  icon: string;
  buttons: {
    title: string;
    link: string;
    style: string;
    link_data: Record<string, string | number | boolean>;
  }[];
}

export interface SpecialistProfileTabsItem {
  id: Nullable<number>;
  text: string; //Название
  count: number; //Кол-во
  is_selected: Nullable<boolean>; //На этом ли табе
}

export interface SpecialistProfileOverviewItem {
  nmb_reviews: Nullable<number>; //Сколько всего оценок
  rate: Nullable<number>; //Средняя оценка
  star1: Nullable<number>; //Кол-во оценок для оценки 1
  star2: Nullable<number>; //Кол-во оценок для оценки 2
  star3: Nullable<number>; //Кол-во оценок для оценки 3
  star4: Nullable<number>; //Кол-во оценок для оценки 4
  star5: Nullable<number>; //Кол-во оценок для оценки 5
}

export interface SpecialistProfileDataMediaItem {
  type: "img" | "video" | "audio" | "file"; //Тип файла
  mimetype: Nullable<string>; //MIME
  extension: Nullable<string>; //Разширение
  name: Nullable<string>; //Имя
  digest: string; //Дайджест
  size: Nullable<number>; //Размер в байтах
  duration: Nullable<string>; //Размер секундах
}

export interface SpecialistProfileReviewItem {
  dashboard: SpecialistProfileOverviewItem; //Объект рейтинга
  data: ReviewsListItem[]; //Отзывы
  tabs: SpecialistProfileTabsItem[]; //Табы отзыва
}

export interface SpecialistProfilePhotosItem extends SpecialistProfileDataMediaItem {
  album_type: "reviews" | "self_uploaded"; //Тип альбома
  rate: Nullable<number>; //Оценка отзыва
  description: Nullable<string>; //Описание
  created_at: Nullable<string>; //Дата создания
  price: Nullable<number>; //Цена
  is_approved: Nullable<boolean> //Опубликована
}

export interface SpecialistProfileNestedPricesItem {
  id: Nullable<number>;
  name: Nullable<string>;
  sum_to: Nullable<number>;
  unit: Nullable<string>
}

export interface SpecialistProfilePricesDataItem {
  id: Nullable<number>;
  work_id: Nullable<number>;
  name: Nullable<string>;
  sum_to: Nullable<number>; //Цена до
  unit: Nullable<string>; //Ед. измерения
  prices: SpecialistProfileNestedPricesItem[];
}

export interface SpecialistProfilePricesItem {
  tabs: SpecialistProfileTabsItem[]; //Объекты табов
  data: SpecialistProfilePricesDataItem[]; //Объекты цен
}

export interface SpecialistProfileInfo {
  id: number;
  avatar: Nullable<string>; //Аватар
  first_name: Nullable<string>; //Имя
  last_name: Nullable<string>; //Фамилия
  about: Nullable<string>; //О себе
  nb_reviews: number; //Кол-во отзывов
  rate: number; //Рейтинг
  about_moderated: boolean; //Проверена ли информация о себе
  avatar_moderated: boolean; //Проверена ли Аватарка
  labels?: { title: string; style: string }[];
  is_favourite?: boolean;
  phone: Nullable<string>;
}

export interface SpecialistProfileItem {
  specialist?: SpecialistProfileInfo;
  review?: SpecialistProfileReviewItem; //Объект с информацией о отзывах
  photos?: SpecialistProfilePhotosItem[]; //Фото и видео специалиста
  prices?: SpecialistProfilePricesItem; //Объект с информацией о ценах
  alert?: SpecialistAlertItem;
  service_id?: number;
  ticket_id?: number;
  bid: Nullable<{ id: number }>;
  state: SpecialistProfileState;
  adf_puid?: { [key: string]: string };
  ad_disabled: boolean;
}

export interface SpecialistPricesListItem {
  id: number;
  name: string;
  nmb_active: number;
  total: number;
}

export interface SpecialistPricesServiceListItem {
  id: Nullable<number>;
  work_id: number;
  name: string;
  sum_to: Nullable<number>;
  unit: Nullable<string>;
}

export interface SpecialistPricesService {
  prices: SpecialistPricesServiceListItem[];
  service: {
    id: number;
    name: string;
    nb_active: number;
    total: number;
  }
}

export interface SpecialistSubWorkPriceItem extends SpecialistPricesServiceListItem {
  recommend: Nullable<string>;
}

export interface SpecialistWorkPriceItem {
  price: {
    id: Nullable<number>;
    work_id: number;
    name: string;
    sum_to: Nullable<number>;
    unit: Nullable<string>;
    prices?: SpecialistSubWorkPriceItem[];
  };
}

export interface SpecialistUpdateWorkPayload {
  price: {
    work_id: number;
    sum_to: Nullable<number>;
    prices?: { work_id?: number, sum_to: Nullable<number> }[];
  }
}

export interface SpecialistMediaList {
  has_more: boolean;
  photos: SpecialistProfilePhotosItem[];
}

export interface RemoveProfileAlert {
  alert: SpecialistAlertItem;
}

export interface ProfilePrices {
  prices: SpecialistProfilePricesDataItem[];
}