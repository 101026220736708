import {useEffect}                                                                  from "react";
import {NativeActions, useAppDispatch, PurchaseType, setPaymentId, setPurchaseType} from "shared/model";
import {useNativeHandler, setActionForNative}                                       from "shared/lib";


interface Props {
  paymentId?: string;
  type: PurchaseType;
}

export const useOpenPayments = ({paymentId, type}: Props) => {
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    if (paymentId && type) {
      dispatch(setPaymentId(paymentId));
      dispatch(setPurchaseType(type));
    }
  }

  useEffect(() => {
    if (!paymentId) return;
    setActionForNative(NativeActions.OPEN_PAYMENTS, {payment_id: paymentId});
  }, [paymentId]);

  useNativeHandler<{ is_payed: boolean }>("tariffs", NativeActions.OPEN_PAYMENTS, data => {
    if (!paymentId) return;
    if (data.is_payed) {
      if (paymentId) {
        return handleSubmit();
      }
    }
  });
}