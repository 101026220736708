import React                                          from "react";
import {useTranslation}                               from "react-i18next";
import {LoadingIcon, GalleryDescription, VideoPlayer} from "shared/ui";
import {Icon}                                         from "shared/v12ui";
import {Nullable}                                     from "shared/model";
import {formatNumberWithSpaces}                       from "shared/lib";
import RatingStars                                    from "../ratingStars/RatingStars";
import cn                                             from "classnames";
import styles                                         from "./ImageWithPreloader.module.scss";


interface Props {
  reviewInfo?: {
    createdAt: Nullable<string>;
    price: Nullable<number>;
    rate: Nullable<number>;
  };
  src: string;
  alt: string;
  type?: string;
  description?: Nullable<string>;
}

export default function ImageWithPreloader({src, type = "img", reviewInfo, description}: Props) {
  const {t} = useTranslation();
  const [loaded, setLoaded] = React.useState(false);
  const [onError, setOnError] = React.useState(false);
  const [isHide, setIsHide] = React.useState(false);
  const [controlsHeight, setControlsHeight] = React.useState(0);

  const handleToggleIsHide = () => setIsHide(prev => !prev);

  const wrapperClassnames = cn(
    styles.photo_wrapper,
    {[styles.has_review]: !!reviewInfo},
  );

  return (
    <div className={wrapperClassnames} onClick={handleToggleIsHide}>
      {!loaded && !onError && <LoadingIcon iconSize={50} classNames={styles.loader} />}
      {onError && <div className={styles.error}>
        <Icon size={32} className="text white mgb-3">info</Icon>

        <span className={styles.error_text}>{t("common.media_error")}</span>
      </div>}

      {type === "video"
        ? <VideoPlayer
          src={src}
          onLoad={() => setLoaded(true)}
          onError={() => setOnError(true)}
          style={{display: (!loaded || onError) ? "none" : "block"}}
          onSetControlsHeight={(value) => setControlsHeight(value as number)}
        />
        : <img
          src={src}
          onLoad={() => setLoaded(true)}
          onError={() => setOnError(true)}
          style={{display: (!loaded || onError) ? "none" : "block"}}
          alt=""
        />}

      <GalleryDescription hasReview={!!reviewInfo} description={description} isHide={isHide}
                          controlsHeight={controlsHeight}>
        {reviewInfo?.createdAt && <div className={styles.info_block}>
          <div className={styles.review_block}>
            <div className={styles.review_text_holder}>
              {reviewInfo?.price
                ? <span className={styles.review_price}>{`${formatNumberWithSpaces(reviewInfo?.price ?? 0)} ₸`}</span>
                : null}
              <span className={styles.review_date}>{reviewInfo?.createdAt}</span>
            </div>
            <div className={styles.stars_holder}>
              <RatingStars rating={reviewInfo?.rate as number} />
            </div>
          </div>
        </div>}
      </GalleryDescription>
    </div>
  );
};