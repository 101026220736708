import React        from "react";
import {Icon}       from "shared/v12ui";
import {TabVariant} from "./tabVariant";
import cn           from "classnames";
import styles       from "./styles.module.scss";


interface Props {
  children?: React.ReactNode;
  activeTab: boolean;
  icon?: string | React.ReactNode;
  rightIcon?: string | React.ReactNode;
  onClick: () => void;
  variant?: TabVariant,
}

const ICON_SIZE = 16;
export default function ElementTabs({
  children,
  onClick,
  activeTab,
  icon,
  rightIcon,
  variant = TabVariant.PRIMARY,
}: Props) {

  return <div
    className={cn(styles.tab, {[styles.tab_active]: activeTab}, {[styles.secondary]: variant === TabVariant.SECONDARY})}
    onClick={onClick}
  >
    <div className={cn(styles.text, {[styles.text_active]: activeTab})}>
      {icon && <Icon className={children ? "mgr-2" : ""} size={ICON_SIZE}>{icon}</Icon>}
      {children}
      {rightIcon && <Icon className="mgl-2" size={ICON_SIZE}>{rightIcon}</Icon>}
    </div>
  </div>
}