import {BaseResponse, RequestMethod, SpecialistProfileUi} from "shared/api";
import {api}                                              from "shared/api/api";
import {
  ProfilePrices,
  SpecialistMediaList,
  SpecialistProfileItem,
  SpecialistProfilePricesDataItem,
  SpecialistProfileTabsItem,
  Nullable,
}                                                         from "shared/model";


const specialistAppProfile = api.injectEndpoints({
  endpoints: (build) => ({
    fetchAppProfile: build.mutation<BaseResponse<SpecialistProfileItem, SpecialistProfileUi> | undefined, {
      specialist_id: Nullable<number>,
      work_id?: Nullable<number>,
      ticket_id?: Nullable<number>,
    }>({
      query:             ({...params}) => ({
        url:    `app/specialist`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<SpecialistProfileItem, SpecialistProfileUi>) => response.status ? response : undefined,
    }),

    fetchProfileAppPrices: build.mutation<ProfilePrices | undefined, {
      specialist_id?: number,
      service_id: Nullable<number>
    }>({
      query:             ({...params}) => ({
        url:    `/app/specialist/prices`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<ProfilePrices>) => response.status ? response.content : undefined,
    }),

    fetchSpecialistAppPhotoTabs: build.mutation<{ tabs: SpecialistProfileTabsItem[] } | undefined, {
      specialist_id: Nullable<number>
    }>({
      query:             ({...params}) => ({
        url:    `app/specialist/photos/tabs`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<{
        tabs: SpecialistProfileTabsItem[]
      }>) => response.status ? response.content : undefined,
    }),

    fetchSpecialistAppPhotosList: build.mutation<SpecialistMediaList | undefined, {
      specialist_id: Nullable<number>,
      tab_id?: Nullable<number>
    }>({
      query:             ({...params}) => ({
        url:    `app/specialist/photos`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<SpecialistMediaList>) => response.status ? response.content : undefined,
    }),

    fetchSpecialistAppPriceTabs: build.mutation<{ tabs: SpecialistProfileTabsItem[] } | undefined, {
      specialist_id: Nullable<number>;
      service_id?: Nullable<number>;
    }>({
      query:             ({...params}) => ({
        url:    `app/specialist/prices/tabs`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<{
        tabs: SpecialistProfileTabsItem[]
      }>) => response.status ? response.content : undefined,
    }),

    fetchSpecialistAppPricesList: build.mutation<{ prices: SpecialistProfilePricesDataItem[] } | undefined, {
      specialist_id: Nullable<number>;
      service_id?: Nullable<number>;
    }>({
      query:             ({...params}) => ({
        url:    `app/specialist/prices`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<{
        prices: SpecialistProfilePricesDataItem[]
      }>) => response.status ? response.content : undefined,
    }),

    toggleSpecialistToFavourite: build.mutation<boolean, {
      specialist_id: number,
      work_id?: number,
      action: "add" | "delete"
    }>({
      query:             ({...params}) => ({
        url:    `app/specialist/favourite`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<boolean>) => response.status,
    }),
  }),
});

export const {
  useFetchProfileAppPricesMutation,
  useFetchSpecialistAppPhotosListMutation,
  useFetchSpecialistAppPhotoTabsMutation,
  useFetchSpecialistAppPriceTabsMutation,
  useFetchSpecialistAppPricesListMutation,
  useToggleSpecialistToFavouriteMutation,
  useFetchAppProfileMutation,
} = specialistAppProfile;