import {NativeActions} from "shared/model";


function postMessageToWebKit(action: NativeActions, data?: any) {
  const message = {action, ...(data && {data})};
  window.webkit?.messageHandlers?.callbackHandler?.postMessage(JSON.stringify(message));
}

function callAndroidMethod(action: NativeActions, data?: any) {
  if (data) {
    window.android[action](JSON.stringify(data));
  } else {
    window.android[action]();
  }
}

function postNativeAction(action: NativeActions, data?: any) {
  if (!window.NativeApp) return

  if (typeof window.NativeApp[action] !== "function") {
    console.warn(`Method ${action} is not a function on NativeApp`);
    return;
  }

  try {
    if (data) {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (isIOS) {
        window.NativeApp[action](data);
      } else {
        window.NativeApp[action](JSON.stringify(data));
      }
    } else {
      window.NativeApp[action]();
    }
  } catch (error) {
    console.warn(`Error calling ${action} on NativeApp:`, error);
  }
}

export function setActionForNative<T>(action: NativeActions, data?: T): void {
  const appVersionFromStaroga = sessionStorage.getItem("appVersion");
  const urlParams = new URLSearchParams(window.location.search);
  const appVersionFromQuery = urlParams.get("version");

  const currentVersion = appVersionFromStaroga ?? appVersionFromQuery;

  if (currentVersion && currentVersion < "12.0.0") {
    if (window?.webkit) {
      postMessageToWebKit(action, data);
    } else if (window?.android) {
      callAndroidMethod(action, data);
    }
  } else {
    postNativeAction(action, data);
  }
}
