import React                                           from "react";
import {useTranslation}                                from "react-i18next";
import {Button, NewButtonColor, NewButtonType}         from "shared/v12ui";
import {InApp}                                         from "shared/ui";
import {setActionForNative, useNativeHandler}          from "shared/lib";
import {NativeActions, useAppDispatch, useAppSelector} from "shared/model";
import {
  selectCameraInAppIsOpen,
  setCameraPermission,
  toggleCameraInApp,
}                                                      from "../model/permissionsSlice";


interface Props {
  limit: number;
  isVideo: boolean;
  hasVideo: boolean;
}

const MEDIA_LIMIT = 5;
const i18nPrefix = "features.permissions.camera."
export default function CameraRequest({limit, hasVideo, isVideo}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const cameraInAppIsOpen = useAppSelector(selectCameraInAppIsOpen);

  const handleCloseInAppClick = () => {
    dispatch(toggleCameraInApp(false));
  }

  const handleRequestCameraPermission = () => {
    setActionForNative(NativeActions.GIVE_CAMERA_PERMISSION);
  }

  const handleOpenBackCamera = () => {
    dispatch(setCameraPermission(true));
    setActionForNative(NativeActions.OPEN_BACK_CAMERA, {
      limit:     MEDIA_LIMIT - limit,
      is_video:  isVideo,
      has_video: hasVideo,
    });
    dispatch(toggleCameraInApp(false));
  }

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.GIVE_CAMERA_PERMISSION, data => {
    if (data.has_permissions) {
      return handleOpenBackCamera();
    } else {
      return dispatch(toggleCameraInApp(false));
    }
  });

  return <>
    {cameraInAppIsOpen && <InApp
      image="/illustrations/selfie-3.svg"
      headerText={t(`${i18nPrefix}text`)}
      headerSubtext={t(`${i18nPrefix}subtext`)}
      open={cameraInAppIsOpen}
      onClose={handleCloseInAppClick}
    >
      <div className="inApp_buttons">
        <Button
          type="button"
          text={t("common.cancel")}
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          onClick={handleCloseInAppClick}
        />
        <Button
          type="button"
          text={t("common.allow")}
          hasSpace
          onClick={handleRequestCameraPermission}
        />
      </div>
    </InApp>}
  </>
};