import {createSlice, PayloadAction} from "@reduxjs/toolkit";


const initialState: {
  isMyAnketyOpened: boolean;
  isMyCreditsOpened: boolean;
  isMyTariffsOpened: boolean;
} = {
  isMyAnketyOpened:  false,
  isMyCreditsOpened: false,
  isMyTariffsOpened: false,
};

const slice = createSlice({
  name:     "analytics",
  initialState,
  reducers: {
    setIsMyAnketyOpened(state, action: PayloadAction<boolean>) {
      state.isMyAnketyOpened = action.payload
    },
    setIsMyCredits(state, action: PayloadAction<boolean>) {
      state.isMyCreditsOpened = action.payload
    },
    setIsMyTariffsOpened(state, action: PayloadAction<boolean>) {
      state.isMyTariffsOpened = action.payload
    },
  },
})

export const {setIsMyAnketyOpened, setIsMyCredits, setIsMyTariffsOpened} = slice.actions;
export default slice.reducer;

export const selectIsMyAnketyOpened = (state: { analytics: typeof initialState }) => state.analytics.isMyAnketyOpened;
export const selectIsMyCreditsOpened = (state: { analytics: typeof initialState }) => state.analytics.isMyCreditsOpened;
export const selectIsMyTariffsOpened = (state: { analytics: typeof initialState }) => state.analytics.isMyTariffsOpened;