import {createSlice, PayloadAction}      from "@reduxjs/toolkit";
import {SpecialistProfileUi}             from "shared/api";
import {Nullable, SpecialistProfileItem} from "shared/model";


interface ProfileType {
  content: SpecialistProfileItem;
  ui?: SpecialistProfileUi;
}

const initialState: {
  avatarIsStartUpload: boolean;
  profile: Nullable<ProfileType>;
  isCashed: boolean;
  myAnketyOpenedIsSend: boolean;
  pricesRoute: Nullable<string>;
} = {
  avatarIsStartUpload:  false,
  profile:              null,
  isCashed:             false,
  myAnketyOpenedIsSend: false,
  pricesRoute:          null,
};

const slice = createSlice({
  name:     "specialistProfile",
  initialState,
  reducers: {
    setAvatarIsStartUpload(state, action: PayloadAction<boolean>) {
      state.avatarIsStartUpload = action.payload
    },
    setProfile(state, action: PayloadAction<Nullable<ProfileType>>) {
      state.profile = action.payload
    },
    setProfileIsCashed(state, action: PayloadAction<boolean>) {
      state.isCashed = action.payload
    },
    setMyAnketyOpenedIsSend(state, action: PayloadAction<boolean>) {
      state.myAnketyOpenedIsSend = action.payload
    },
    setPricesRoute(state, action: PayloadAction<Nullable<string>>) {
      state.pricesRoute = action.payload
    },
  },
})

export const {
  setAvatarIsStartUpload,
  setProfile,
  setProfileIsCashed,
  setMyAnketyOpenedIsSend,
  setPricesRoute,
} = slice.actions;
export default slice.reducer;


export const selectAvatarIsStartUpload = (state: {
  specialistProfile: typeof initialState
}) => state.specialistProfile.avatarIsStartUpload;
export const selectProfile = (state: {
  specialistProfile: typeof initialState
}) => state.specialistProfile.profile;
export const selectProfileIsCashed = (state: {
  specialistProfile: typeof initialState
}) => state.specialistProfile.isCashed;
export const selectMyAnketyOpenedIsSend = (state: {
  specialistProfile: typeof initialState
}) => state.specialistProfile.myAnketyOpenedIsSend;
export const selectPricesRoute = (state: {
  specialistProfile: typeof initialState
}) => state.specialistProfile.pricesRoute;