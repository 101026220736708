import React                            from "react";
import {nativeMethod}                   from "shared/lib";
import {useAppDispatch, useAppSelector} from "shared/model";
import {
  selectCameraPermission,
  selectGalleryPermission,
  selectGeolocationPermission,
  selectPushPermission,
  setCameraPermission,
  setGalleryPermission,
  setGeolocationPermission,
  setPushPermission,
}                                       from "shared/native/permissions";


export default function useNativePermission() {
  const dispatch = useAppDispatch();
  const cameraPermission = useAppSelector(selectCameraPermission);
  const galleryPermission = useAppSelector(selectGalleryPermission);
  const geolocationPermission = useAppSelector(selectGeolocationPermission);
  const pushPermission = useAppSelector(selectPushPermission);

  console.log(cameraPermission, "cameraPermission");
  console.log(galleryPermission, "galleryPermission");

  React.useEffect(() => {
    if (cameraPermission === null) {
      nativeMethod().checkCameraPermission().then((res) => {
        dispatch(setCameraPermission(res.has_permissions));
      }).catch((err) => console.warn(err))
    }
  }, [cameraPermission]);

  React.useEffect(() => {
    if (galleryPermission === null) {
      nativeMethod().checkGalleryPermission().then((res) => {
        dispatch(setGalleryPermission(res.has_permissions));
      }).catch((err) => console.warn(err))
    }
  }, [galleryPermission]);

  React.useEffect(() => {
    if (geolocationPermission === null) {
      nativeMethod().checkGeoPermission().then((res) => {
        dispatch(setGeolocationPermission(res.has_permissions));
      }).catch((err) => console.warn(err))
    }
  }, [geolocationPermission]);

  React.useEffect(() => {
    if (pushPermission === null) {
      nativeMethod().checkPushPermission().then((res) => {
        dispatch(setPushPermission(res.has_permissions));
      }).catch((err) => console.warn(err))
    }
  }, [pushPermission]);
};