import {combineReducers, configureStore}                            from "@reduxjs/toolkit";
import {rtkQueryErrorLogger}                                        from "shared/api";
import {api}                                                        from "shared/api/api";
import {analytics, generals, purchases, queries, specialistProfile} from "shared/model";
import registration                                                 from "pages/registration/model/registration"
import {permissions}                                                from "shared/native/permissions";
import {order}                                                      from "entities/order";


const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  registration,
  generals,
  purchases,
  analytics,
  queries,
  permissions,
  specialistProfile,
  order,
});

export const store = configureStore({
  reducer:    (state, action) => {
    return appReducer(state, action);
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat([api.middleware]).concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;