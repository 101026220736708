import {profile}        from "../pages/specialist/profile/i18n/kk";
import {order}          from "entities/order/i18n/kk"
import {client_profile} from "pages/client/profile/i18n/kk";
import {permissions}    from "shared/native/permissions/i18n/kk";
import {city_select}    from "features/global/citySelect/i18n/kk";
import {upload_media}   from "features/global/uploadMedia/i18n/kk";
import {map}            from "widgets/yaMap/i18n/kk";

const kk = {
  page:            {
    registration: {
      header:              "Маманды тіркеу",
      footer:              "Анкетаны тіркеу және көшіру бойынша барлық сұрақтарға жауаптарды таба аласыз",
      egov:                {
        header: {
          description: "eGov mobile арқылы",
        },
        form:   {
          text:            "EGov mobile базасынан деректерді алу үшін ЖСН мен телефон нөмірін көрсетіңіз. Бұл бұғатталған анкетаны (алаяқтар, делдалдар және т. б.) қайта тіркеуге қарсы тұруға көмектеседі",
          ssn_label:       "ЖСН",
          ssn_placeholder: "Өз ЖСН-ді енгізіңіз",
          ssn_subtext:     "Куәліктегі 12 таңбалы код",
          phone_label:     "Телефон нөмірі",
        },
        otp:    {
          header:              "SMS - тен кодты енгізіңіз",
          description_chunk_1: "Алынған смс кодты енгізу арқылы сіз өзіңіздің дербес деректеріңізді жинауға және өңдеуге, сондай-ақ ақпарат беруге және кредиттік бюродан есеп алуға келісіміңізді бересіз және сіз танысып, келісесіз",
          description_chunk_2: "Пайдаланушы келісімі",
          timer:               "{{count}} сек. кейін қайтадан.",
          end_time:            "Қайта сұрау",
          error_code:          "Қате код",
        },
      },
      transfer:            {
        header:   {
          title:       "Анкетаны көшіру",
          description: "Менде naimi-де анкета бар",
        },
        form:     {
          ssn_label:       "ЖСН",
          ssn_placeholder: "Өз ЖСН-ді енгізіңіз",
          ssn_subtext:     "Куәліктегі 12 таңбалы код",
        },
        complete: {
          title:    "Анкета көшірілді",
          subtitle: "Сіздің анкетаңыз жаңа нөмірге сәтті көшірілді. Қосымша арқылы төлем алу үшін банк картасын қайта енгізіңіз",
          button:   "Өтінімдер тізімі",
        },
      },
      non_resident:        {
        header: {
          description: "Бейрезиденті үшін",
        },
        text:   {
          chunk_1: "Егер сізде ЖСН болмаса, оны жақын маңдағы ХҚКО-нан алыңыз. Толық нұсқауларды көруге болады",
          chunk_2: "мына жерден",
        },
      },
      manual_registration: {
        header: {
          description: "Қолмен тіркелу",
        },
        text:   "ЖСН ешкім көрмейді, ол бойынша жүйе бұғатталған мамандарды қайта тіркеуге жол бермейді",
      },
      document:            {
        header: {
          title:                    "Маманды тіркеу",
          description:              "Куәлік бойынша",
          description_non_resident: "Бейрезиденті үшін",
        },
        list:   {
          item_1:              "Жақсы сападағы фотосуретті қолданыңыз",
          item_2:              "Туған күніңіз, аты-жөніңіз және ЖСН анық көрінетініне көз жеткізіңіз",
          item_3:              "Фотосуретте тек куәліктің алдыңғы жағы болуы керек",
          non_resident_item_1: "Фотосуретте тек сіздің өз еліңіздің құжаты болуы керек",
          non_resident_item_2: "Айналаңызда жақсы жарық бар екеніне көз жеткізіңіз",
          non_resident_item_3: "Сапалы фотосуреттерді қолданыңыз",
          non_resident_item_4: "Құжат көлденең орналасуы тиіс",
        },
      },
      freedom:             {
        header: {
          title: "Анкета жасау қажет пе?",
        },
        form:   {
          ssn:       "ЖСН",
          birth_day: "Туған күн",
          text:      "Сіз Freedom Finance банк картасын ашу кезінде жеке басты растаудан өттіңіз. Деректеріңіздің дұрыстығын растайтын болсаңыз, анкета жасалады. Сіздің ЖСН және туған күніңіз анкетада  көрсетілмейді.",
          buttons:   {
            not_me: "Жоқ, бұл мен емес",
            create: "Иә, жасау",
          },
        },
      },
    },
    verification: {
      header: "Жеке басты тексеру",
    },
    specialist:   {
      tariffs:            {
        header:                "Тариф",
        faq:                   {
          text:   "Танымал сұрақтар",
          button: "Тағы",
        },
        notification:          "Автоматты түрде жаңарту іске қосылды",
        notification_unfreeze: "Тариф қайта іске қосылды",
        info:                  {
          freeze:   {
            title:       "Тарифті {{count}} күнге уақытша тоқтату керек пе?",
            description: {
              part_1: "Тарифтің аяқталуына {{count}} күн қалды,",
              part_2: "ол {{date}} мерзімге дейін уақытша тоқтатылады. Тарифті қайта іске қосу кез-келген уақытта болады",
            },
          },
          unfreeze: {
            title:       "Тарифті қайта іске қосу керек пе? ",
            description: "Расталған сәтте біз тарифті {{count}} күнге іске қосамыз, содан соң сіз өтінімдерге ұсыныс қалдыра аласыз",
          },
          buttons:  {
            freeze:   "Тоқтата тұру",
            unfreeze: "Қайта жалғастыру",
            pay:      "Төлемге {{price}} ₸",
          },
        },
      },
      purchase_catalogue: {
        header:                "Анкета каталогта орналасқан",
        footer:                "Белгілі бір санат бойынша 5 рет ұсыныс жасағаннан кейін, анкетаңыз каталогта тегін пайда болады. Егер анкета 7 күн ішінде қайта көтерілмесе, онда каталогтан өшіріледі.",
        notification:          "Анкета 1-орынға орналасады жақын арада",
        delete_catalogue_item: "Анкета каталогтан жойылды",
        add_category:          {
          title:       "Санаттарды қосу",
          subtitle:    "Каталогта 1-орынға орналасу үшін",
          description: "+ {{price}} ₸ / шт",
        },
        delete_category:       {
          title:    "Анкетаны каталогтан өшіресіз бе?",
          subtitle: "Сіздің анкетаңыз таңдалған санаттардан өшіріледі. Клиенттер сізбен каталог арқылы байланыса алмайтын болады.",
          buttons:  {
            cancel: "Жою",
            delete: "Өшіру",
          },
        },
      },
      my_category:        {
        header: {
          title:    "Менің санаттарым",
          subtitle: "Анкетаны каталогқа қосу",
        },
        plug:   {
          title:    "Санаттарды таңдаңыз",
          subtitle: "Анкетаны каталогқа орналастыру үшін сізді қызықтыратын қызмет санаттарын таңдаңыз",
          button:   "Санаттарды таңдаңыз",
        },
        footer: "Белгілі бір санат бойынша 5 рет ұсыныс жасағаннан кейін, анкетаңыз каталогта тегін пайда болады. Егер анкета 7 күн ішінде қайта көтерілмесе, онда каталогтан өшіріледі.",
        button: "Дайын",
      },
      tariff_periods:     {
        header: {
          title:    "Тарифты сатып алу",
          subtitle: "Мерзімді таңдау",
        },
        plug:   {
          title:    "Санаттарды таңдаңыз",
          subtitle: "Ыңғайлы тариф мерзімін таңдау үшін, сізді қызықтыратын тапсырыстар және вакансиялар санатын таңдаңыз.",
          button:   "Санаттарды таңдаңыз",
        },
        footer: "Бағалар сіз үшін күнделікті жеке есептеліп отырады, алайда автоматты түрде жаңартумен тарифте баға өзгеріссіз қалады. Және төлем тек банк картасы арқылы жасалынады.",
      },
      tariff_options:     {
        auto_renewal_prompt: "Автоматты түрде жаңарту көрсетілмеген мерзімді таңдаңыз",
        buttons:             {
          save:  "Сақтау",
          pay:   "Төлеу",
          price: "Төлемге {{price}} ₸",
        },
      },
      credits:            {
        header: "Бөліп төлеу және несие",
        plug:   {
          title:    "Бос",
          subtitle: "Сізде бөліп төлеу және несие жоқ",
          button:   "Тарифты сатып алу",
        },
        footer: "Банктағы қарыздар туралы ақпарат синхрондалмаған, және де тек танысу мақсатында көрсетілген.",
      },
      credits_schedule:   {
        header:  "Төлем кестесі",
        buttons: {
          credit_prepayment: "Мерзімінен бұрын төлеу",
          loan_expired:      "Төлеу",
          price:             "Төлемге {{price}}  ₸",
        },
      },
      profile,
    },
    client:       {
      profile: client_profile,
    },
    state:        {
      auto_renewal:        {
        title:  "Автоматты түрде жаңарту",
        button: "Түсінікті",
        list:   {
          row_1: "Автоматты түрде жаңарту бағаны сатып алынған мерзімдегідей сақтайды",
          row_2: "Автоматты түрде жаңартусыз баға күнделікті жаңарып отырады, ал автоматты түрде жаңартумен 50% үнемдеуге болады",
          row_3: "Төлем банк картасынан автоматты түрде шешіліп отырады, қолмен қосып отырудың қажеті жоқ",
        },
      },
      auto_renewal_cancel: {
        header:         "Автоматты түрде жаңартуды доғару",
        notification:   "Автоматты түрде жаңарту өшірілді",
        buttons:        {
          cancel: "Жоқ, мен ойымды өзгерттім",
          submit: "Автоматты түрде жаңартуды доғару",
        },
        confirm_cancel: {
          title:   "Автоматты түрде жаңартуды өшіру нақты шешіміңіз бе?",
          buttons: {
            cancel: "Авто жаңартуды қалдыру",
            submit: "Бәрібір бас тарту",
          },
        },
      },
    },
    reviews:      {
      title:              "Пікірлер",
      rating:             "{{count}} бағалар",
      not_reviews:        "Маманның бұл қызмет туралы пікірлері әлі жоқ, бірақ сіз осы маманның басқа жұмысы туралы қолданушылардың пікірлерін оқи аласыз",
      sort:               {
        title:     "Сұрыптау",
        date_desc: "Жаңадан ескіге дейін",
        date_asc:  "Ескіден жаңаға дейін",
        rate_desc: "Жоғарыдан төмен бағаларға дейін",
        rate_asc:  "Төменнен жоғары бағаларға дейін",
      },
      not_approved:       "Пікір әлі тексерілмеген. Тексеруден кейін клиенттің орындалған жұмыс туралы пікірі  пайда болады.",
      not_approved_label: "Тексерілуде",
    },
    customers:    {
      cash_loans: {
        button:         "Тапсырыс беру",
        loading_screen: {
          check:        {
            title:       "Тексеру жүруде",
            description: "Күтіңіз, сервистің ішкі тексерулері жүріп жатыр",
          },
          loan_process: {
            title:       "Рәсімдеу жүріп жатыр",
            description: "Күте тұрыңыз, рәсімдеме өңделуде",
          },
        },
        state:          {
          denied:           {
            title:  "Беруден бас тартылды",
            button: "Қайталап көріңіз",
          },
          select_card:      {
            title:  "Келісім алынды",
            button: "Картаны таңдаңыз",
          },
          success:          {
            title:  "Рәсімделді",
            button: "Өтінімдер тізімі",
          },
          error:            {
            title:  "Қате",
            button: "Қолдау қызметі",
          },
          empty_categories: {
            title:       "Санаттарды таңдаңыз",
            description: "Жалғастыру үшін сізді қызықтыратын қызмет санаттарын таңдаңыз",
            button:      "Санаттарды таңдау",
          },
        },
      },
      invitation: {
        title:         "Достарды шақыру",
        copy_button:   "Көшіру үшін басыңыз",
        submit_button: "Промокодты жіберу",
        copy_toast:    "Промокод көшірілді",
        bonuses:       "Шақыру бонустары",
        questions:     "Танымал сұрақтар",
      },
    },
    order,
  },
  component:       {
    identity: {
      header:         "Жеке басты тексеру",
      list:           {
        item_1: "Айналаңызда жақсы жарық бар екеніне көз жеткізіңіз",
        item_2: "Бетті жабуы мүмкін заттар мен көзілдірікті алып тастаңыз",
        item_3: "Бетіңізді сопақ аймаққа орналастырыңыз",
      },
      list_photo:     {
        item_1: "Бетіңізді анық көрсетіп, суретке түсіріңіз",
        item_2: "Айналаңызда жақсы жарық бар екеніне көз жеткізіңіз",
        item_3: "Көзілдірікті, масканы және бет бөліктерін жабатын басқа заттарды алып тастаңыз",
      },
      text_block:     "Бейне делдалдан, жеке басын ауыстырудан және тіркеу кезінде басқа адамдардың құжаттарын пайдаланудан қорғауға бағытталған. Тексеру кезінде алынған фотосурет сіздің анкетаңыздағы аватар ретінде орнатылады, сіз оны тіркеуден кейін кез келген уақытта өзгерте аласыз",
      confirm_button: "Бейне тексеруден өту",
      verification:   {
        title:             "Тексеруді күтіңіз",
        transfer_subtitle: "Анкетаны жаңа нөмірге ауыстыру туралы өтініш өңделуде. Жаңа нөмірден жұмыс істеу үшін біз деректерді тексереміз",
        manual_subtitle:   "Жеке басты растауды менеджерлеріміз тексереді. Күту уақыты шамамен 24 сағат",
        subtitle:          "Жүйе сіздің бетіңізді тани алмады. Жеке басты растауды менеджерлеріміз тексереді. Күту уақыты 24 сағатқа дейін",
        button:            "Өтінімдер тізімі",
        freedom:           "Сіз карта сәтті ашылғаннан кейін тіркелесіз. Күту уақыты 24 сағатқа дейін",
      },
    },
    manual:   {
      form: {
        name_label:             "Аты",
        name_placeholder:       "Атыңызды енгізіңіз",
        lastname_label:         "Тегі",
        lastname_placeholder:   "Тегіңізді енгізіңіз",
        gender_label:           "Жынысы",
        gender_placeholder:     "Сіздің жынысыңыз",
        birth_date_label:       "Туған күн",
        birth_date_placeholder: "кк.аа.жжжж",
        ssn_label:              "ЖСН",
        ssn_placeholder:        "Өз ЖСН-ді енгізіңіз",
      },
    },
    cropper:  {
      header: {
        title: "Фотосуретті қию",
      },
      text:   "Құжаттың таңдалған аймақтың ортасында екеніне көз жеткізіңіз. Құжаттың тек алдыңғы жағын бетіңіз көрінген жерге орналастырыңыз",
      scale:  "Масштаб",
      button: "Қию",
    },
  },
  in_app:          {
    biometric_error:    {
      title:       "Тұлға расталмаған",
      description: "Сіз жеке басты тексеруден өткен жоқсыз. Жарықтандыруды тексеріп, қайталап көріңіз",
      buttons:     {
        try_again: "Қайталап көру",
        manual:    "Қолмен тіркеуден өту",
      },
    },
    document_error:     {
      title:         "Құжат танылмады",
      description_1: "Себептері: нашар жарықтандыру, фото масштабы, негізгі ақпарат көрінбейді",
      description_2: "Құжаттағы деректерді оқу мүмкін емес. Қолмен тіркеуді өтіңіз",
      buttons:       {
        try_again: "Қайталап көру",
        manual:    "Қолмен енгізу",
      },
    },
    ssn_error:          {
      title:       "ЖСН табылмады",
      description: "Сіз енгізген ЖСН деректер базада табылмады. Деректер қате жазылған болуы мүмкін, қайта жазып көріңіз",
      button:      "Қайталап көру",
    },
    birth_day:          {
      header: "Туған күні",
    },
    gender:             {
      header: "Жынысты көрсетіңіз",
      male:   "Ер",
      female: "Әйел",
    },
    ssn_exist:          {
      title:       "Бұл ЖСН бар",
      description: "Бұл ЖСН-мен тіркелген пайдаланушы біздің дерекқорда бар. Сіз өзіңіздің анкетаңызды осы нөмірге ауыстыруыңыз керек",
      button:      "Анкетаны көшіру",
    },
    upload_photo_error: {
      title:       "Фотосуретті жүктеу мүмкін емес",
      description: "Фотосуретті тағы да жүктеп көріңіз",
      button:      "Қайталап көру",
    },
    close_registration: {
      title:       "Тіркеуді тоқтату керек пе?",
      description: "Егер сіз тіркеуді қазір тоқтатсаңыз, қайтадан жасау кезінде барлығын қайта бастауға және қажет болған жағдайда деректерді толтыруға тура келеді",
    },
    close_verification: {
      title:       "Жеке басты растауды тоқтатасыз ба?",
      description: "Егер жеке куәлікті тексеруді қазір тоқтатсаңыз, барлық деректерді қайта басынан бастауға және керек жерлерде қайта толтыруға тура келеді.",
    },
    wrong_number:       {
      title:       "Қате телефон нөмірі",
      description: "Енгізілген телефон нөмірі eGov mobile базасындағы ЖСН-ге байланысты нөмірмен сәйкес келмейді. EGov mobile телефон нөмірін көрсетіңіз немесе басқа жолмен тіркеліңіз",
      button:      {
        try_again: "Басқа телефон нөмірін енгізу",
        cancel:    "Басқа әдісті таңдау",
      },
    },
    ssn_blocked:        {
      title:       "Тіркеуден бас тартылды",
      description: "Осы ЖСН бойынша тіркелу мүмкін емес. Сервистің ішкі тексерулері бойынша сізге тіркеуден бас тартылды",
      button:      "Түсінікті",
    },
  },
  elements:        {
    image_loading:    {
      title:       "Фотосуретті жүктеу",
      description: "Күте тұрыңыз, біз сіздің фотосуретіңізді біздің серверге жүктеудеміз",
    },
    image_load_error: {
      title:       "Фотосуретті жүктеу мүмкін болмады",
      description: "Фотосуретті тағы да жүктеп көріңіз",
      button:      "Қайталап көру",
    },
  },
  state:           {
    guest:           "Жаңадан қосылған",
    waiting_payment: "Төлем өңделуде, ақпарат жақын арада пайда болады",
    payed:           "Төлем жасалынды",
    not_payed:       "Төлем жасалынған жоқ",
    pay_error:       "Төлем барысында қате пайда болды",
  },
  card_buttons:    {
    credits_schedule:  "Төлем кестесі",
    credit_prepayment: "Мерзімінен бұрын төлеу",
    loan_expired:      "Төлеу",
    edit:              "Өзгерту",
    freeze:            "Тоқтата тұру",
    unfreeze:          "Қайта жалғастыру",
    partner_site:      "Серіктестің сайты",
  },
  lost_connection: {
    title:       "Қосылу қатесі",
    description: "Құрылғыңыздың интернетке қосылғанына көз жеткізіңіз",
  },
  features:        {
    permissions,
    city_select,
    upload_media,
  },
  widgets:         {
    map,
  },
  common:          {
    select:        "Таңдау",
    exit:          "Шығу",
    continue:      "Жалғастыру",
    allow:         "Рұқсат беру",
    next:          "Келесі",
    cancel:        "Бас тарту",
    edit:          "Өзгерту",
    save:          "Сақтау",
    delete:        "Жою",
    ready:         "Дайын",
    close:         "Жабу",
    interrupt:     "Тоқтату",
    check_loading: "Код тексеріп жатырмыз",
    how_it_works:  "Бұл қалай жұмыс істейді?",
    media_error:   "Файл қолжетімсіз",
    toggle:        {
      more:     "Тағы",
      collapse: "Жию",
    },
  },
};

export default kk;