import React             from "react";
import Lottie            from "lottie-react";
import {loaderAnimation} from "app/styles/assets/lotties";
import {NewButtonType}   from "../model/newButtonType";
import {NewButtonColor}  from "../model/newButtonColor";
import cn                from "classnames";
import styles            from "./ButtonLoading.module.scss";


interface Props {
  buttonType: NewButtonType;
  buttonColor: NewButtonColor;
  isRounded?: boolean;
}

export default function ButtonLoading({isRounded = false, buttonType, buttonColor}: Props) {
  return <div className={styles.loading_wrapper}>
    <Lottie
      autoPlay
      animationData={loaderAnimation}
      loop
      className={cn(
        styles.loading,
        {[`${styles[buttonType]}`]: buttonType},
        {[`${styles[buttonColor]}`]: buttonColor},
        {[`${styles.isRounded}`]: isRounded},
      )}
    />
  </div>
};
