import {BaseResponse, RequestMethod} from "shared/api";
import {api}                         from "shared/api/api";
import {PurchaseState}               from "shared/model";


const customerPaymentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPurchaseState: build.mutation<BaseResponse<{ state: PurchaseState }>, { id: string, mode?: "app" | "pro" }>({
      query: ({id, mode}) => ({
        url:    `${mode ?? "pro"}/balance/purchase/state`,
        method: RequestMethod.PUT,
        body:   {payment_id: id},
      }),
    }),
  }),
});

export const {useGetPurchaseStateMutation} = customerPaymentsApi;