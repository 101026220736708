import {InputHTMLAttributes, KeyboardEvent, useEffect, useRef} from "react";
import cn                                                      from "classnames";
import styles                                                  from "./ChatInput.module.scss";


interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  errorMessage?: string;
}

export default function ChatInput({errorMessage, ...props}: Props) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    textarea.style.height = "2.2rem";
    const scrollHeight = textarea.scrollHeight - 20;
    const newHeight = textarea.scrollHeight <= 86 ? scrollHeight : 86;

    textarea.style.height = `${newHeight}px`;
  };

  const handleKeydown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      const textarea = textareaRef.current;
      if (textarea) {
        const cursorPosition = textarea.selectionStart;
        const value = textarea.value;
        textarea.value = value.slice(0, cursorPosition) + "\n" + value.slice(cursorPosition);
        textarea.selectionStart = cursorPosition + 1;
        textarea.selectionEnd = cursorPosition + 1;
        handleInput();
      }
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const handleKeydownWrapper = (event: KeyboardEvent) => handleKeydown(event as KeyboardEvent<HTMLTextAreaElement>);

    textarea.addEventListener("input", handleInput);
    //@ts-ignore
    textarea.addEventListener("keydown", handleKeydownWrapper);
    handleInput();

    return () => {
      textarea.removeEventListener("input", handleInput);
      //@ts-ignore
      textarea.removeEventListener("keydown", handleKeydownWrapper);
    };
  }, [textareaRef]);

  useEffect(() => {
    if (textareaRef.current?.value === "") {
      textareaRef.current.style.height = "2.2rem";
    }
  }, [textareaRef.current?.value]);

  return <div className={styles.wrapper}>
    <textarea
      ref={textareaRef} {...props}
      onFocus={() => {
        // document.body.style.setProperty("padding-bottom", "0px", "important");
      }}
      onBlur={() => {
        // document.body.style.setProperty("padding-bottom", "var(--saib)", "important");
      }}
      className={cn(styles.input, {[styles.error]: !!errorMessage})}
      style={{overflowY: "auto", minHeight: "2.2rem"}}
    />
  </div>
};