export  enum LabelColorType {
  ACCENT_1 = "accent_1",
  ACCENT_2 = "accent_2",
  ACCENT_3 = "accent_3",
  SUCCESS = "success",
  ERROR = "error",
  PRIMARY = "primary",
  FREEDOM_1 = "freedom_1",
  FREEDOM_2 = "freedom_2",
  GREY = "grey"
}